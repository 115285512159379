import { useState } from 'react';
import { parseJSON } from 'api';

type UseSessionStateResponse<T> = [T, (value: T) => void];

export default function useSessionState<T>(
  key: string,
  defaultValue: T
): UseSessionStateResponse<T> {
  function load() {
    try {
      const json = sessionStorage.getItem(key);
      if (json) {
        return parseJSON(json) as unknown as T;
      }
      return defaultValue;
    } catch {
      return defaultValue;
    }
  }

  const [value, setValue] = useState<T>(load());

  const updateValue = (newValue: T) => {
    setValue(newValue);
    sessionStorage.setItem(key, JSON.stringify(newValue));
  };

  return [value, updateValue];
}
