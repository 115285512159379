import classNames from 'classnames';
import React, { useState } from 'react';

type AlertType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';

interface Props {
  type?: AlertType;
  dismissable?: boolean;
  children: React.ReactNode;
}

const Alert: React.FunctionComponent<Props> = ({
  type = 'info',
  dismissable = false,
  children,
}) => {
  const [dismissed, setDismissed] = useState(false);
  const [dismissing, setDismissing] = useState(false);

  const dismiss = () => {
    setDismissing(true);

    setTimeout(() => {
      setDismissing(false);
      setDismissed(true);
    }, 150);
  };

  if (dismissed) return null;

  const className = classNames(`alert alert-${type} text-center`, {
    'alert-dismissible fade': dismissable,
    show: !dismissing && !dismissed,
  });

  return (
    <div className={className} role="alert">
      {dismissable && (
        <button
          data-testid="dismiss_alert"
          type="button"
          className="close"
          aria-label="Close"
          onClick={dismiss}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
      {children}
    </div>
  );
};

export default Alert;
