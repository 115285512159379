import React from 'react';

interface Props {
  selectedFilter: string;
  searchBoxTerm: string;
  setSearchBoxTerm: (type: string) => void;
  setSelectedFilter: (type: string) => void;
}

const GuestSearch: React.FunctionComponent<Props> = ({
  selectedFilter,
  searchBoxTerm,
  setSearchBoxTerm,
  setSelectedFilter,
}) => {
  const changeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBoxTerm(e.target.value);
  };

  const changeFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(e.target.value);
  };

  return (
    <div className="GuestSearch">
      <div className="row">
        <div className="col-6 col-sm-8">
          <div className="form-group my-0">
            <label htmlFor="search" className="visually-hidden">
              Search
            </label>
            <input
              data-testid="guest_search"
              type="search"
              className="form-input-area"
              id="search"
              placeholder="Search"
              value={searchBoxTerm}
              onChange={changeSearchTerm}
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
            />
          </div>
        </div>

        <div className="col-6 col-sm-4">
          <div className="form-group my-0">
            <label htmlFor="filter" className="visually-hidden">
              Filter purchases
            </label>
            <select
              data-testid="guest_search_filter"
              className="form-select"
              id="filter"
              value={selectedFilter}
              onChange={changeFilter}
            >
              <option value="all">All guests</option>
              <option value="checkedIn">Checked in</option>
              <option value="partiallyCheckedIn">Partially checked in</option>
              <option value="notCheckedIn">Not checked in</option>
              <option value="notes">Notes</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestSearch;
