import LoginForm from 'components/LoginForm';
import LoggedOutLayout from 'layout/LoggedOutLayout';
import React from 'react';

const LoginPage = () => {
  return (
    <LoggedOutLayout>
      <div className="row">
        <div className="col-12 col-sm-6 offset-sm-3 bg-white p-3">
          <LoginForm />
        </div>
      </div>
    </LoggedOutLayout>
  );
};

export default LoginPage;
