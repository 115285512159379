import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  checkInTicketPurchase as apiCheckInTicketPurchase,
  getTicketPurchases,
} from 'api';
import CheckInStats from 'components/CheckInStats';
import LoaderWithEmptyState from 'components/core/LoaderWithEmptyState';
import Empty from 'components/Empty';
import GuestList from 'components/GuestList';
import GuestSearch from 'components/GuestSearch';
import {
  CurrentScheduleItemProps,
  withCurrentScheduleItem,
} from 'components/withCurrentScheduleItem';
import useSessionState from 'hooks/useSessionState';
import LoggedInLayout from 'layout/LoggedInLayout';
import TicketPurchase, {
  clearSearchMatches,
  hasNote,
  isCheckedIn,
  partiallyCheckedIn,
  search,
} from 'models/TicketPurchase';
import { useAuth } from 'providers/AuthProvider';

type PurchasesFilter = (tp: TicketPurchase) => boolean;

const FILTERS: { [FilterName: string]: PurchasesFilter } = {
  all: () => true,
  checkedIn: (tp: TicketPurchase) => isCheckedIn(tp),
  partiallyCheckedIn: (tp: TicketPurchase) =>
    partiallyCheckedIn(tp) && !isCheckedIn(tp),
  notCheckedIn: (tp: TicketPurchase) => !partiallyCheckedIn(tp),
  notes: (tp: TicketPurchase) => hasNote(tp),
};

const GuestListPage: React.FC<CurrentScheduleItemProps> = ({
  currentScheduleItem,
  currentEvent,
}) => {
  const { data: purchases, mutate } = useSWR(
    ['ticket-purchases', currentEvent.locator, currentScheduleItem.id],
    () => {
      return getTicketPurchases(currentEvent.locator, currentScheduleItem.id);
    }
  );
  const { user } = useAuth();

  const checkInTicketPurchase = async (ticketPurchase: TicketPurchase) => {
    await apiCheckInTicketPurchase(
      currentEvent.locator,
      ticketPurchase,
      user.id
    );

    // TODO: Find updated `ticketPurchase` in `purchases`, update it and mutate the array
    mutate();
  };

  const [selectedFilter, setSelectedFilter] = useSessionState(
    'guestFilter',
    'all'
  );
  const [searchBoxTerm, setSearchBoxTerm] = useSessionState('guestSearch', '');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredPurchases, setFilteredPurchases] = useState(purchases);
  const [filtered, setFiltered] = useState(false);

  const noResults =
    filtered && filteredPurchases && filteredPurchases.length === 0;

  useEffect(() => {
    setFiltered(selectedFilter != 'all');

    const filter = FILTERS[selectedFilter];
    let filtered = purchases?.filter(filter) || [];

    if (!selectedCategory && searchBoxTerm.length < 2) {
      filtered.forEach((purchase) => {
        clearSearchMatches(purchase);
      });
    }
    if (selectedCategory) {
      filtered = filtered.filter((p) =>
        search(p, selectedCategory, ['package'], [])
      );
      setFiltered(true);
    }
    if (searchBoxTerm.length > 1) {
      filtered = filtered.filter((p) =>
        search(
          p,
          searchBoxTerm,
          [
            'firstName',
            'lastName',
            'section',
            'row',
            'seat',
            'originalOrderNumber',
            'phone',
            'seatLocation',
          ],
          ['Email']
        )
      );
      setFiltered(true);
    }

    setFilteredPurchases(filtered);
  }, [purchases, selectedFilter, selectedCategory, searchBoxTerm]);

  if (!user) {
    return null;
  }

  return (
    <LoggedInLayout
      stickyHeader={false}
      currentEvent={currentEvent}
      currentScheduleItem={currentScheduleItem}
      headerLeftContent={
        <CheckInStats
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          purchases={purchases}
        />
      }
    >
      <LoaderWithEmptyState
        isLoading={!purchases}
        isEmpty={purchases?.length === 0}
        emptyMessage="There are no ticket purchases for this show"
      >
        <div className="GuestList__Header pt-4 sticky-top">
          <GuestSearch
            selectedFilter={selectedFilter}
            searchBoxTerm={searchBoxTerm}
            setSearchBoxTerm={setSearchBoxTerm}
            setSelectedFilter={setSelectedFilter}
          />
          <hr className="my-4" />
        </div>
        {noResults && <Empty message="No results found" />}
        {filteredPurchases && (
          <GuestList
            purchases={filteredPurchases}
            checkInTicketPurchase={checkInTicketPurchase}
          />
        )}
      </LoaderWithEmptyState>

      {/* <ScannerBar /> */}
    </LoggedInLayout>
  );
};

export default withCurrentScheduleItem(GuestListPage);
