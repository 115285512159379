import React from 'react';
import LoadingSpinner from 'components/core/LoadingSpinner';
import Empty from 'components/Empty';

interface Props {
  isLoading: boolean;
  isEmpty: boolean;
  emptyMessage: string;
  children: React.ReactNode;
}

const LoaderWithEmptyState: React.FC<Props> = ({
  isLoading,
  children,
  isEmpty,
  emptyMessage,
}) => {
  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (isEmpty) {
    return <Empty message={emptyMessage} />;
  }
  return <>{children}</>;
};

export default LoaderWithEmptyState;
