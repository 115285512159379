import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAuth } from 'providers/AuthProvider';
import buttonPath from 'images/user-menu.svg';
import './styles.scss';

interface UserMenuProps {
  eventList?: boolean;
  width?: number;
}

const UserMenu: React.FunctionComponent<UserMenuProps> = ({
  eventList = false,
  width = 50,
}) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const { user } = useAuth();

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };
  const closeMenu = () => setOpen(false);

  useEffect(() => {
    document.body.addEventListener('click', closeMenu);
    setEmail(user.email);

    return () => {
      document.body.removeEventListener('click', closeMenu);
    };
  }, [user]);

  const className = classNames(
    ['UserMenu__Menu shadow', 'dropdown-menu', 'dropdown-menu-over'],
    {
      show: open,
    }
  );
  return (
    <div className="UserMenu dropdown">
      <button
        type="button"
        data-testid="user_menu_open_button"
        className="UserMenu__Button btn btn-sm btn-link rounded-circle"
        onClick={openMenu}
      >
        <img width={width} src={buttonPath} />
      </button>

      <div className={className}>
        <div className="dropdown-header rounded-top active">
          <button
            data-testid="user_menu_close_button"
            type="button"
            className="close float-right text-white"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="text-secondary text-uppercase small">User</div>
          {email}
        </div>
        {eventList && (
          <a className="dropdown-item" href="/">
            Event list
          </a>
        )}
        <a className="dropdown-item" href="/logout">
          Logout
        </a>
      </div>
    </div>
  );
};

export default UserMenu;
