import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import ErrorMessage from './core/ErrorMessage';
import Form from './core/Form';

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const doLogin = async () => {
    try {
      await login(email, password);
      navigate('/');
    } catch (e) {
      setError(
        e.message ||
          'There was an error contacting StagePilot, please try again.'
      );
    }
  };

  return (
    <Form className="LoginForm" onSubmit={doLogin}>
      <h1 className="h5 mb-4 font-weight-normal text-center">
        Sign in using your StagePilot credentials
      </h1>

      <ErrorMessage messages={[error]} />

      <div className="form-group">
        <label htmlFor="inputEmail">Email address</label>
        <input
          data-testid="email_input"
          type="email"
          name="email"
          className="form-control"
          placeholder="Email address"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required={true}
        />
        <div className="invalid-feedback">Please provide an email.</div>
      </div>

      <div className="form-group">
        <label htmlFor="inputPassword">Password</label>
        <input
          data-testid="password_input"
          type="password"
          name="password"
          className="form-control"
          placeholder="Password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required={true}
        />
        <div className="invalid-feedback">Please provide a password.</div>
      </div>

      <button className="btn btn-lg btn-primary w-100">Sign in</button>
    </Form>
  );
}
