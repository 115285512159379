import cacheStore from 'api/CacheStore';
import useServiceWorkerMessage from 'hooks/useServiceWorkerMessage';
import React, { createContext, useEffect, useMemo, useState } from 'react';

export interface SyncStateProviderType {
  syncing: boolean;
  pending: number;
}

export const SyncStateContext = createContext<SyncStateProviderType>(null!);

interface Props {
  children: React.ReactNode;
}

export default function SyncStateProvider({ children }: Props) {
  const [syncing, setSyncing] = useState<boolean>(false);
  const [pending, setPending] = useState<number>(0);

  useEffect(() => {
    const initPending = async () => {
      await cacheStore.isInitialized();
      setPending(cacheStore.pending);
    };

    initPending();
  }, []);

  const syncStateMessage =
    useServiceWorkerMessage<SyncStateProviderType>('syncstate');

  useEffect(() => {
    if (syncStateMessage) {
      setSyncing(syncStateMessage.syncing);
      setPending(syncStateMessage.pending);
    }
  }, [syncStateMessage]);

  const value: SyncStateProviderType = useMemo(() => {
    return {
      syncing,
      pending,
    };
  }, [syncing, pending]);

  return (
    <SyncStateContext.Provider value={value}>
      {children}
    </SyncStateContext.Provider>
  );
}
