import React from 'react';
import { generatePath, Routes, Route } from 'react-router-dom';
import ChooseEventPage from './pages/ChooseEventPage';
import GuestListPage from './pages/GuestListPage';
import LoginPage from './pages/LoginPage';
import NotesPage from './pages/NotesPage';
import ScannerConfirmationPage from './pages/ScannerConfirmationPage';
import ScannerPage from './pages/ScannerPage';
import TicketPage from './pages/TicketPage';
import Logout from './components/Logout';

export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
export const GUEST_LIST_PATH = '/:locator/:scheduleItemId/guests';
export const PURCHASE_PATH =
  '/:locator/:scheduleItemId/ticketPurchases/:purchaseId';
export const NOTES_PATH =
  '/:locator/:scheduleItemId/ticketPurchases/:purchaseId/notes';

const Routing = () => {
  return (
    <Routes>
      <Route path={LOGIN_PATH} element={<LoginPage />} />
      <Route path={LOGOUT_PATH} element={<Logout />} />
      <Route path="/" element={<ChooseEventPage />} />
      <Route path={GUEST_LIST_PATH} element={<GuestListPage />} />
      <Route path={PURCHASE_PATH} element={<TicketPage />} />
      <Route path={NOTES_PATH} element={<NotesPage />} />
      <Route path="/scanner" element={<ScannerPage />} />
      <Route path="/confirmation" element={<ScannerConfirmationPage />} />
    </Routes>
  );
};

export const guestListPath = (locator: string, scheduleItemId: string) =>
  generatePath(GUEST_LIST_PATH, { locator, scheduleItemId });

export const ticketPurchasePath = (
  locator?: string,
  scheduleItemId?: string | number | null,
  purchaseId?: string | number | null
) =>
  generatePath(PURCHASE_PATH, {
    locator: locator ?? null,
    scheduleItemId: '' + (scheduleItemId ?? ''),
    purchaseId: '' + (purchaseId ?? ''),
  });

export const notesPath = (
  locator?: string,
  scheduleItemId?: number | null,
  purchaseId?: number | null
) =>
  generatePath(NOTES_PATH, {
    locator: locator ?? null,
    scheduleItemId: '' + (scheduleItemId ?? ''),
    purchaseId: '' + (purchaseId ?? ''),
  });

export default Routing;
