import React, { MouseEventHandler } from 'react';
import { Link, useParams } from 'react-router-dom';
import GuestTicket from 'components/GuestTicket';
import NotesBadge from 'components/NotesBadge';
import SearchMatches from 'components/SearchMatches';
import TicketPackage from 'components/TicketPackage';
import TicketPurchase, { fullName } from 'models/TicketPurchase';
import MerchandiseList from './MerchandiseList';
import { ticketPurchasePath } from 'Routing';

interface GuestProps {
  ticketPurchase: TicketPurchase;
  checkInTicketPurchase: (ticketPurchase: TicketPurchase) => Promise<any>;
}

interface GuestParams {
  [key: string]: string;
  locator: string;
  scheduleItemId: string;
}

const Guest: React.FunctionComponent<GuestProps> = ({
  ticketPurchase,
  checkInTicketPurchase,
}) => {
  const handleCheckIn: MouseEventHandler = (e) => {
    e.preventDefault();
    checkInTicketPurchase(ticketPurchase);
  };

  const tickets = ticketPurchase.tickets.map((t, idx) => (
    <GuestTicket
      key={'ticket-' + t.id || `idx-${idx}`}
      checkedIn={t.checkedIn}
    />
  ));

  const { locator, scheduleItemId } = useParams<GuestParams>();
  const merchandise = ticketPurchase.ticketPackage.merchandiseItems;

  return (
    <Link
      className="Guest d-block border my-3 mx-0 bg-white text-decoration-none"
      to={ticketPurchasePath(locator, scheduleItemId, ticketPurchase.id)}
    >
      <div className="d-flex flex-row text-dark">
        <TicketPackage ticketPackage={ticketPurchase.ticketPackage} />
        <div className="flex-fill p-3 mb-2">
          <div className="row">
            <div className=" col-6 col-sm-6 d-flex align-items-center">
              <div className="Guest__Name mr-2">{fullName(ticketPurchase)}</div>{' '}
              <NotesBadge ticketPurchase={ticketPurchase} />
            </div>
            <div className="Quantity col-6 col-sm-6 text-end text-break">
              {tickets}
            </div>
          </div>
          <div className="Guest__Merchandise mt-2">
            <MerchandiseList merchandise={merchandise} />
          </div>
          <div className="Guest__SearchMatches mt-2">
            <SearchMatches ticketPurchase={ticketPurchase} />
          </div>
          <div
            className="Guest__CheckInAll btn btn-sm btn-rounded btn-info text-white mt-sm-0 float-end"
            onClick={handleCheckIn}
          >
            Check in
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Guest;
