import Alert from 'components/core/Alert';
import React, { useEffect, useState } from 'react';

function GlobalError() {
  const [error, setError] = useState<string>();

  const captureError = (e: ErrorEvent) => {
    setError(e.error.message);
  };

  const captureUnhandledRejection = (e: PromiseRejectionEvent) => {
    let message = e.reason?.message;

    if (e.reason.name === 'APIErrorResponse') {
      message =
        'There was a problem connecting to the StagePilot server. Please, try again or refresh the page.';
    }

    setError(message);
  };

  useEffect(() => {
    window.addEventListener('error', captureError);
    window.addEventListener('unhandledrejection', captureUnhandledRejection);

    return () => {
      window.removeEventListener('error', captureError);
      window.removeEventListener(
        'unhandledrejection',
        captureUnhandledRejection
      );
    };
  });

  if (!error) return null;

  return (
    <div className="GlobalError">
      <Alert type="danger" dismissable={true}>
        <p>{error} </p>
      </Alert>
    </div>
  );
}

export default GlobalError;
