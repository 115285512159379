import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import ServiceWorkerProvider from 'providers/ServiceWorkerProvider';
import SyncStateProvider from 'providers/SyncStateProvider';
import App from './App';
import './errorReporting';

// Enable :active pseudo-class in Mobile Safari
/* eslint-disable-next-line @typescript-eslint/no-empty-function */
document.addEventListener('touchstart', () => {}, true);

const rootDOM = ReactDOM.createRoot(document.getElementById('root'));
rootDOM.render(
  <ServiceWorkerProvider>
    <SyncStateProvider>
      <Router>
        <App />
      </Router>
    </SyncStateProvider>
  </ServiceWorkerProvider>
);
