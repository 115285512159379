import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { logOut } from 'api';
import CacheStore from 'api/CacheStore';
import { setCurrentUser } from 'models/CurrentUser';
import { LOGIN_PATH } from 'Routing';

const Logout: React.FC = () => {
  const [done, setDone] = useState(false);

  useEffect(() => {
    const submit = async () => {
      await logOut();
      await CacheStore.clear();
      window.localStorage.clear();
      window.sessionStorage.clear();
      setCurrentUser(null);
      setDone(true);
    };

    submit();
  }, []);

  return done ? <Navigate to={LOGIN_PATH} /> : null;
};

export default Logout;
