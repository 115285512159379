import Link from 'models/Link';
import Note from 'models/Note';
import Ticket from 'models/Ticket';
import TicketPackage from 'models/TicketPackage';
import User from 'models/User';

export default interface TicketPurchase {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  firstName: string;
  lastName: string;
  quantity: number;
  seatLocation: string;
  section: string;
  row: string;
  seat: string;
  receiptID: string;
  originalOrderNumber: string;
  phone: string;
  package: string;
  importedNotes: string;
  notes: Note[];
  scheduleItemId: number;
  customFields: object;
  ticketPackage: TicketPackage;
  tickets: Ticket[];
  ticketLinks: Link[];
  searchMatches?: string[];
}

interface SearchedTicketPurchase extends TicketPurchase {
  name: string;
  matches: object;
}

export interface TicketPurchaseActivity {
  category: string;
  timestamp: Date;
  performedBy: User;
  action: string;
}

export const fullName = (tp: TicketPurchase) => {
  return [tp.firstName, tp.lastName].join(' ');
};

export const hasNote = (tp: TicketPurchase) => {
  return tp.importedNotes.trim().length > 0 || tp.notes.length > 0;
};

export const notesCount = (tp: TicketPurchase) => {
  const imported = hasImportedNote(tp) ? 1 : 0;
  return imported + tp.notes.length;
};

export const isCheckedIn = (tp: TicketPurchase) => {
  return tp.tickets.every((t) => t.checkedIn);
};

export const partiallyCheckedIn = (tp: TicketPurchase) => {
  return tp.tickets.some((t) => t.checkedIn);
};

export const search = (
  tp: TicketPurchase,
  q: string,
  surfaceSearchFields: string[],
  customSearchFields: string[]
) => {
  try {
    const searchString = q.trim();

    const searched: SearchedTicketPurchase = Object.assign(
      { name: `${tp.firstName} ${tp.lastName}`, matches: {} },
      tp
    );

    const matchesSurfaceKeys = matchingFields(
      searched,
      searchString,
      surfaceSearchFields
    );
    const customFieldMatches = matchingFields(
      searched.customFields,
      searchString,
      customSearchFields
    );

    searched.matches = Object.assign(matchesSurfaceKeys, customFieldMatches);

    const matchedKeys = Object.keys(searched.matches);
    const isMatch = matchedKeys.length > 0;
    tp.searchMatches = isMatch ? matchedKeys : undefined;

    return isMatch && searched.matches;
  } catch (error) {
    console.error('Invalid searchString', error);
    return false;
  }
};

const matchingFields = (
  o: object,
  searchString: string,
  searchFields: string[]
) => {
  const matching: { [Key: string]: string } = {};

  searchFields = searchFields.map(function (f) {
    return f.toLowerCase();
  });

  for (const [key, value] of Object.entries(o)) {
    if (!searchFields.includes(key.toLowerCase())) continue;
    if (/Links?$/.exec(key)) continue;
    if (typeof value === 'string') {
      valueMatchesSearchString(value, searchString) && (matching[key] = value);
    } else if (value && typeof value === 'object') {
      Object.assign(
        matching,
        matchingFields(value, searchString, searchFields)
      );
    }
  }
  return matching;
};

const valueMatchesSearchString = (value: string, searchString: string) => {
  const valuesArray = value.split(/[^A-Z0-9]+/gi);
  const startsWithSearch = valuesArray.filter((value) =>
    value.toLowerCase().startsWith(searchString.toLowerCase())
  );
  if (value === searchString) startsWithSearch.push(value);
  return !!startsWithSearch.length;
};

const hasImportedNote = (tp: TicketPurchase) => {
  return tp.importedNotes.trim().length > 0;
};

export const clearSearchMatches = (tp: TicketPurchase) => {
  tp.searchMatches = null;
};
