import React from 'react';
import ApplicationError from 'components/ApplicationError';
import ConnectionIndicator from 'components/ConnectionIndicator';
import VersionChecker from 'components/VersionChecker';
import { AuthProvider } from 'providers/AuthProvider';
import Routing from './Routing';
import './App.scss';

function App() {
  return (
    <ApplicationError>
      <AuthProvider>
        <ConnectionIndicator />
        <Routing />
        <VersionChecker />
      </AuthProvider>
    </ApplicationError>
  );
}

export default App;
