import { useContext } from 'react';
import {
  SyncStateContext,
  SyncStateProviderType,
} from 'providers/SyncStateProvider';

export default function useSyncState(): SyncStateProviderType {
  const context = useContext(SyncStateContext);
  if (!context) {
    throw new Error('useSyncState can only be used within a SyncStateProvider');
  }
  return context;
}
