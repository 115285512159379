import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { logIn } from 'api';
import { loadCurrentUser } from 'models/CurrentUser';
import User from 'models/User';
import { LOGIN_PATH } from 'Routing';
import LoadingSpinner from 'components/core/LoadingSpinner';

const AuthContext = createContext<any>(null);

interface IAuthContext {
  user: User | null;
  authenticate: () => Promise<User>;
  login: (email: string, password: string) => Promise<User>;
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState<User | null>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user || !localStorage['TOKEN']) authenticate();
  }, []);

  async function authenticate(): Promise<User | null> {
    try {
      const currentUser = await loadCurrentUser();
      if (currentUser && localStorage['TOKEN']) {
        setUser(currentUser);
        setLoading(false);
        return currentUser;
      } else {
        window.localStorage.clear();
        navigate(LOGIN_PATH);
        setLoading(false);
        return null;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      return null;
    }
  }

  const login = async (email: string, password: string) => {
    const { user, token } = await logIn(email, password);
    setUser(user);
    window.localStorage.setItem('TOKEN', token);
    return user;
  };

  const value = useMemo(
    () => ({
      user,
      authenticate,
      login,
    }),
    [user]
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext<IAuthContext>(AuthContext);
}
