import React from 'react';
import MerchandiseItem from 'models/MerchandiseItem';

interface MerchandiseListProps {
  merchandise: MerchandiseItem[];
}

const MerchandiseList: React.FunctionComponent<MerchandiseListProps> = ({
  merchandise,
}) => {
  if (!merchandise || merchandise.length === 0) return null;
  const names = merchandise.map((merch) => {
    return merch.name;
  });

  return (
    <div className="small">
      <span className="SearchMatch__Field text-capitalize text-muted">
        {'Merchandise: '}
      </span>
      {names.join(', ')}
    </div>
  );
};

export default MerchandiseList;
