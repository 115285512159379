import React from 'react';

import TicketPurchase from 'models/TicketPurchase';

interface SearchMatchesProps {
  ticketPurchase: TicketPurchase;
}

// These fields already appear in the Guest component
const IGNORED_FIELDS = ['name', 'firstName', 'lastName'];

const SearchMatches: React.FunctionComponent<SearchMatchesProps> = ({
  ticketPurchase,
}) => {
  if (!ticketPurchase.searchMatches) return null;

  const matches = ticketPurchase.searchMatches.filter(
    (m) => !IGNORED_FIELDS.includes(m)
  );

  if (matches.length === 0) return null;

  return (
    <ul className="list-unstyled">
      {matches.map((m) => {
        const value = ticketPurchase[m] || ticketPurchase.customFields[m];

        if (!value) return false;

        return <SearchMatch field={m} value={value} key={m} />;
      })}
    </ul>
  );
};

interface SearchMatchProps {
  field: string;
  value: string;
}

const SearchMatch: React.FunctionComponent<SearchMatchProps> = ({
  field,
  value,
}) => {
  return (
    <li className="small">
      <span className="SearchMatch__Field text-capitalize text-muted">
        {field}
      </span>
      : <span className="SearchMatch__Value">{value}</span>
    </li>
  );
};

export default SearchMatches;
