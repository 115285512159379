import React from 'react';
import { TicketPurchaseActivity } from 'models/TicketPurchase';
import { formatDate } from 'utils';
import './style.scss';

interface Props {
  activity: TicketPurchaseActivity;
}

export function formatTime(timestamp: Date | string): string {
  const d = timestamp instanceof Date ? timestamp : new Date(timestamp);
  return formatDate(d);
}

const Activity: React.FC<Props> = ({ activity }) => {
  return (
    <div className="Activity">
      <span className="Activity__Header">
        <span className="text-muted">{formatTime(activity.timestamp)}</span>
        {': '}
        <span>{activity.action}</span>
        {' by '}
        <span className="text-primary">{activity.performedBy.email}</span>
      </span>
    </div>
  );
};

export default Activity;
