/* eslint @typescript-eslint/no-explicit-any: 0 */
import Event from 'models/Event';
import Link from 'models/Link';
import MerchandiseItem from 'models/MerchandiseItem';
import { NoteUpdate } from 'models/Note';
import ScheduleItem from 'models/ScheduleItem';
import Ticket, {
  addHandoverToTicket,
  getHandoversFromTicket,
  removeHandoverFromTicket,
} from 'models/Ticket';
import TicketPurchase from 'models/TicketPurchase';
import User from 'models/User';

export type IDType = number | string;

const apiHost = process.env.API_HOST || '/api';

export const logIn = async (email: string, password: string) => {
  const resp = await callApi<any>('POST', '/login', { email, password });
  return resp as { user: User; token: string };
};

export const logOut = async () => {
  await callApi('DELETE', '/logout');
};

export const getEvents = async () => {
  const resp = await callApi<any>('GET', `/events`);
  return resp.events as Event[];
};

export const getScheduleItems = async (locator: string) => {
  const resp = await callApi<any>('GET', `/events/${locator}`);
  return resp.scheduleItems as ScheduleItem[];
};

export const getScheduleItem = async (
  locator: string,
  scheduleItemId: IDType
) => {
  const resp = await callApi<any>(
    'GET',
    `/events/${locator}/${scheduleItemId}`
  );
  return resp.scheduleItem as ScheduleItem;
};

export const getCurrentUser = async () => {
  try {
    const resp = await callApi('GET', `/user`);
    return resp as User;
  } catch (e) {
    if (e.details.status === 403) {
      return null;
    }

    throw e;
  }
};

export const getTicketPurchases = async (
  locator: string,
  scheduleItemId: number
) => {
  const resp: Link[] = await callApi<any>(
    'GET',
    `/events/${locator}/${scheduleItemId}/ticketPurchases`
  );

  return await hydrateCollection<TicketPurchase>(resp);
};

export const getEventStats = async (
  locator: string,
  scheduleItemId?: number
) => {
  let path = `/events/${locator}/stats`;
  if (scheduleItemId) {
    path = path + `?scheduleItemId=${scheduleItemId}`;
  }

  return await callApi('GET', path);
};

export const getTicketPurchase = async (
  locator: string,
  scheduleItemId: IDType,
  id: IDType
) => {
  const resp: TicketPurchase = await callApi<any>(
    'GET',
    `/events/${locator}/${scheduleItemId}/ticketPurchases/${id}`
  );

  return resp;
};

export const getTicketPurchaseActivity = async (
  locator: string,
  scheduleItemId: IDType,
  id: IDType
) => {
  const ticketPurchaseActivity = await callApi(
    'GET',
    `/events/${locator}/${scheduleItemId}/ticketPurchases/${id}/activity`
  );

  return ticketPurchaseActivity;
};

export const updateTicketPurchase = async (
  locator: string,
  scheduleItemId: number,
  ticketPurchase: TicketPurchase
) => {
  const resp: TicketPurchase = await callApi<any>(
    'PATCH',
    `/events/${locator}/${scheduleItemId}/ticketPurchases/${ticketPurchase.id}`,
    ticketPurchase
  );

  return resp;
};

export const checkInTicketPurchase = async (
  locator: string,
  ticketPurchase: TicketPurchase,
  userId: number
) => {
  await Promise.all(
    ticketPurchase.tickets.map((ticket) => {
      return checkInTicket(locator, ticketPurchase, ticket, userId);
    })
  );

  return ticketPurchase;
};

export const toggleTicketCheckin = async (
  locator: string,
  ticketPurchase: TicketPurchase,
  ticket: Ticket,
  userId: number
) => {
  if (ticket.checkedIn) {
    return removeTicketCheckin(locator, ticketPurchase, ticket);
  } else {
    return checkInTicket(locator, ticketPurchase, ticket, userId);
  }
};

export const checkInTicket = async (
  locator: string,
  ticketPurchase: TicketPurchase,
  ticket: Ticket,
  currentUserId: number
) => {
  const ticketPackage = ticketPurchase.ticketPackage;
  if (ticketPackage.merchandiseEnabled) {
    ticketPackage.merchandiseItems.forEach((mi) => {
      addHandoverToTicket(ticket, mi.id, currentUserId);
    });
  }

  ticket.checkedIn = true;

  const { id, merchandiseHandovers, checkedIn } = ticket;
  return updateTicket(locator, {
    id,
    merchandiseHandovers,
    checkedIn,
  } as Ticket);
};

export const removeTicketCheckin = async (
  locator: string,
  ticketPurchase: TicketPurchase,
  ticket: Ticket
) => {
  const ticketPackage = ticketPurchase.ticketPackage;
  if (ticketPackage.merchandiseEnabled) {
    ticketPackage.merchandiseItems.forEach((mi) => {
      removeHandoverFromTicket(ticket, mi.id);
    });
  }

  ticket.checkedIn = false;

  const { id, merchandiseHandovers, checkedIn } = ticket;
  return updateTicket(locator, {
    id,
    merchandiseHandovers,
    checkedIn,
  } as Ticket);
};

const updateTicket = async (locator: string, ticket: Ticket) => {
  const resp = await callApi(
    'PATCH',
    `/events/${locator}/tickets/${ticket.id}`,
    ticket
  );

  return resp as Ticket;
};

export const handOverItem = async (
  locator: string,
  merchandiseItem: MerchandiseItem,
  ticket: Ticket,
  currentUserId: number
) => {
  addHandoverToTicket(ticket, merchandiseItem.id, currentUserId);
  return updateTicket(locator, getHandoversFromTicket(ticket));
};

export const removeHandover = async (
  locator: string,
  merchandiseItem: MerchandiseItem,
  ticket: Ticket
) => {
  removeHandoverFromTicket(ticket, merchandiseItem.id);
  return updateTicket(locator, getHandoversFromTicket(ticket));
};

export const createNote = async (
  locator: string,
  ticketPurchase: TicketPurchase,
  newNote: NoteUpdate
) => {
  const update = {
    id: ticketPurchase.id,
    notes: [newNote],
  } as TicketPurchase;

  return updateTicketPurchase(locator, ticketPurchase.scheduleItemId, update);
};

export const callApi = async <T>(
  method: string,
  path: string,
  params: object | undefined = undefined
) => {
  const body = params ? JSON.stringify(params) : null;
  const token = localStorage.getItem('TOKEN');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const fetchParams: RequestInit = {
    credentials: 'include',
    method,
    headers,
    body,
  };

  if (body) {
    fetchParams.body = body;
  }

  const request = new Request(apiHost + path, fetchParams);
  const resp = await fetch(request);

  let respBody: T | Error;

  if (resp.headers.get('Content-Type') === 'application/json') {
    respBody = await resp.json();
    await hydrateLinks(respBody as object);
  } else {
    respBody = new Error('unknown error');
  }

  if (!resp.ok) {
    const errorMessage = (respBody as any)?.reason || (await resp.text());

    const error = new APIErrorResponse(method, path, resp.status, errorMessage);
    throw error;
  }

  return respBody;
};

class APIErrorResponse extends Error {
  details: object;

  constructor(method: string, path: string, status: number, message: string) {
    super(message || 'API responded with an error');
    this.name = 'APIErrorResponse';
    this.details = {
      method,
      path,
      status,
      message,
    };
  }
}

interface Hydrations {
  [Key: string]: Promise<object>;
}

// hydrateLinks hydrates all the links in a model recursively. It looks
// for properties ending in Link or Links and fetches and updates the linked
// models.
const hydrateLinks = async (model: Record<string, any>) => {
  const keys: string[] = Object.keys(model);
  const modelLinks = keys.filter((k) => k.endsWith('Link'));
  const collectionLinks = keys.filter((k) => k.endsWith('Links'));

  const hydrations: Hydrations = {};

  modelLinks.forEach((attr) => {
    hydrations[attr] = hydrateLink(model[attr] as Link);
  });

  collectionLinks.forEach((attr) => {
    hydrations[attr] = hydrateCollection(model[attr]);
  });

  await Promise.all(Object.values(hydrations));

  Object.entries(hydrations).forEach(([link, promise]) => {
    const attr = link.replace(/(s)?Links$/, 's').replace(/Link$/, '');
    promise.then((result) => {
      model[attr] = result;
    });
  });
};

async function hydrateCollection<T>(links: Link[]): Promise<T[]> {
  return Promise.all(links.map((link) => hydrateLink<T>(link)));
}

async function hydrateLink<T>(link: Link): Promise<T> {
  const apiURL = link.link.replace(/^\/api\//, '/'); // remove "/api" from provided links
  const result = await callApi('GET', apiURL);
  await hydrateLinks(result as any);
  return result as T;
}

export function parseJSON(text: string): object {
  function dateReviver(key: string, value: object): object | null {
    if (/\w+At$/.exec(key) && typeof value === 'string') {
      return new Date(value);
    }
    return value;
  }

  return JSON.parse(text, dateReviver);
}
