import logoPath from 'images/logo-light.svg';
import React, { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

const LoggedOutLayout: React.FunctionComponent<Props> = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('LoggedOut', 'bg-dark');
    document.body.classList.remove('LoggedIn');
  });

  return (
    <div className="LoggedOutLayout">
      <div className="LoggedInLayout__Logo my-5 text-center">
        <img alt="StagePilot" src={logoPath} width="150" />
      </div>
      <div className="container py-3">{children}</div>
    </div>
  );
};

export default LoggedOutLayout;
