import React from 'react';
import TicketCard from 'components/TicketCard';
import TicketPurchase from 'models/TicketPurchase';

interface TicketListProps {
  ticketPurchase: TicketPurchase;
}

const TicketList: React.FunctionComponent<TicketListProps> = ({
  ticketPurchase,
}) => {
  return (
    <div className="row">
      {ticketPurchase.tickets.map((ticket, idx) => (
        <div
          key={'ticket-' + ticket.id || `idx-${idx}`}
          className="col-12 col-md-6 mb-3"
        >
          <TicketCard ticketPurchase={ticketPurchase} ticket={ticket} />
        </div>
      ))}
    </div>
  );
};

export default TicketList;
