import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { toggleTicketCheckin } from 'api';
import Merchandise from 'components/Merchandise';
import Ticket, { seat } from 'models/Ticket';
import TicketPurchase from 'models/TicketPurchase';
import { useAuth } from 'providers/AuthProvider';

interface TicketProps {
  ticketPurchase: TicketPurchase;
  ticket: Ticket;
}

const TicketCard: React.FunctionComponent<TicketProps> = ({
  ticketPurchase,
  ticket,
}) => {
  const [currentTicket, setCurrentTicket] = useState(ticket);
  const checkedIn = currentTicket.checkedIn;

  useEffect(() => {
    setCurrentTicket(ticket);
  }, [ticket]);

  const className = classNames('Ticket card cursor-pointer', {
    'Ticket--checkedIn': checkedIn,
  });

  const headerClassName = classNames('card-header', {
    'text-white bg-success': checkedIn,
  });

  const { locator } = useParams<Record<string, string>>();
  const { user } = useAuth();

  const ticketPackage = ticketPurchase.ticketPackage;

  const toggleCheckin = async () => {
    const updated = await toggleTicketCheckin(
      locator,
      ticketPurchase,
      currentTicket,
      user.id
    );
    setCurrentTicket(updated);
  };

  return (
    <div
      className={className}
      id={`ticket_${ticket.id}`}
      onClick={toggleCheckin}
    >
      <div className={headerClassName}>{ticket.name}</div>
      <div className="card-body">
        <ul className="list-unstyled">
          <li>Ticket package: {ticketPackage.name}</li>
          <li>Section: {ticketPurchase.section}</li>
          <li>Row: {ticketPurchase.row}</li>
          <li>Seat: {seat(ticket)}</li>

          {ticketPackage.merchandiseEnabled && (
            <Merchandise
              ticket={currentTicket}
              ticketPurchase={ticketPurchase}
              onTicketUpdate={(updated: Ticket) => {
                setCurrentTicket(Object.assign({}, updated));
              }}
            />
          )}
        </ul>
      </div>
    </div>
  );
};

export default TicketCard;
