import React from 'react';
import classNames from 'classnames';
import SyncStatusIndicator from 'components/SyncStatusIndicator';
import UserMenu from 'components/UserMenu';
import { CurrentScheduleItemProps } from 'components/withCurrentScheduleItem';
import { logoPath } from 'models/Event';

interface Props extends CurrentScheduleItemProps {
  leftContent?: React.ReactNode;
  stickyHeader: boolean;
}

const LoggedInHeader: React.FunctionComponent<Props> = ({
  currentEvent,
  currentScheduleItem,
  stickyHeader,
  leftContent,
}) => {
  const className = classNames('LoggedInHeader', {
    'sticky-top': stickyHeader,
  });

  return (
    <div className={className}>
      <div className="container position-relative">
        <div className="row">
          <div className="col-3">
            <div className="text-left h-100 d-flex flex-column justify-content-center">
              {leftContent}
            </div>
          </div>
          <div className="col-6 text-center py-md-5 py-2">
            <div className="LoggedInHeader__EventLogo d-none d-md-block">
              <img
                className="img-fluid"
                style={{ maxHeight: 40 }}
                alt={currentEvent.name}
                src={logoPath(currentEvent)}
              />
            </div>
            <h1 className="h6 my-1 text-dark">
              <span className="d-block d-md-inline mb-2 pr-md-2">
                {currentScheduleItem.location}
              </span>
              {currentScheduleItem.startsAt} {currentScheduleItem.time}
            </h1>
          </div>
          <div className="col-3 d-flex flex-column justify-content-center">
            <div className="float-end d-flex flex-column align-items-center justify-content-center">
              <UserMenu eventList />
              <SyncStatusIndicator />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoggedInHeader;
