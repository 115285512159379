import React from 'react';
import TicketPackageModel from 'models/TicketPackage';
import './styles.scss';

interface Props {
  ticketPackage: TicketPackageModel;
}

const TicketPackage: React.FunctionComponent<Props> = ({ ticketPackage }) => {
  if (!ticketPackage.color) return null;

  return (
    <div
      className="TicketPackage d-column"
      style={{ backgroundColor: ticketPackage.color }}
    >
      <div className="h-100 visually-hidden">{ticketPackage.name}</div>
    </div>
  );
};

export default TicketPackage;
