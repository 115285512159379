declare let GIT_VERSION: string;

import React, { useEffect, useState } from 'react';
import useOnline from 'hooks/useOnline';
import useServiceWorker from 'hooks/useServiceWorker';
import {
  WorkerMessage,
  WorkerMessageType,
} from 'providers/ServiceWorkerProvider';

export default function VersionChecker() {
  const [outdated, setOutdated] = useState(false);
  const [latest, setLatest] = useState('');
  const current = GIT_VERSION;
  const online = useOnline();
  const { wb } = useServiceWorker();

  useEffect(() => {
    let cancelled = false;

    const fetchVersion = async () => {
      const resp = await fetch('/version');
      const latest = await resp.text();

      if (!cancelled) {
        setLatest(latest);
      }
    };

    fetchVersion();

    return () => {
      cancelled = true;
    };
  }, []);

  useEffect(() => {
    if (latest && current) {
      setOutdated((outdated) => outdated && latest !== current);
      console.log('[App] Version check', {
        current,
        latest,
        outdated: latest !== current,
      });
    }
  }, [latest, current]);

  useEffect(() => {
    const wbCheck = async () => {
      const response = await wb?.messageSW(
        WorkerMessage(WorkerMessageType.VersionCheck, { latest })
      );

      setOutdated((outdated) => outdated && response.outdated);
      console.log('[SW] Version check', response);
    };

    latest && wb && wbCheck();
  }, [wb, latest]);

  if (online && latest && current && outdated) {
    return (
      <div className="VersionChecker alert alert-danger" role="alert">
        Out of date!{' '}
        <a href="" className="alert-link" onClick={location.reload}>
          Refresh to load update.
        </a>
      </div>
    );
  }

  return null;
}
