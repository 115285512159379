import React from 'react';
import { Link } from 'react-router-dom';

const GuestListBar = () => {
  return (
    <div className="navbar fixed-bottom navbar-light bg-light justify-content-center py-3">
      <Link to="/guests" className="btn btn-secondary text-white">
        Guest list
      </Link>
    </div>
  );
};

export default GuestListBar;
