import User from './User';
import { getCurrentUser as getCurrentUserFromAPI } from 'api';

let currentUser: User | null = null;

export const setCurrentUser = (u: User | null) => {
  currentUser = u;
};

export const loadCurrentUser = async () => {
  currentUser = await getCurrentUserFromAPI();
  return currentUser;
};

export const getCurrentUser = () => {
  return currentUser;
};
