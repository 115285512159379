import React from 'react';
import { useParams } from 'react-router-dom';
import { handOverItem, removeHandover } from 'api';
import MerchandiseHandover from 'models/MerchandiseHandover';
import MerchandiseItemModel from 'models/MerchandiseItem';
import Ticket from 'models/Ticket';
import TicketPurchase from 'models/TicketPurchase';
import { useAuth } from 'providers/AuthProvider';

interface Props {
  ticketPurchase: TicketPurchase;
  ticket: Ticket;
  onTicketUpdate: (updated: Ticket) => void;
}

const Merchandise: React.FunctionComponent<Props> = ({
  ticketPurchase,
  ticket,
  onTicketUpdate,
}) => {
  const ticketPackage = ticketPurchase.ticketPackage;

  if (!ticketPackage.merchandiseEnabled) return null;

  const items = ticketPackage.merchandiseItems;

  return (
    <form
      className="Merchandise row border-top my-3 py-3"
      id={`merchandise-items-${ticket.id}`}
    >
      {items.map((item) => {
        return (
          <div className="col-4" key={`${item.id}-${ticket.id}`}>
            <MerchandiseItem
              item={item}
              ticket={ticket}
              onTicketUpdate={onTicketUpdate}
            />
          </div>
        );
      })}
    </form>
  );
};

interface MerchandiseItemProps {
  item: MerchandiseItemModel;
  ticket: Ticket;
  onTicketUpdate: (updated: Ticket) => void;
}

const MerchandiseItem: React.FunctionComponent<MerchandiseItemProps> = ({
  item,
  ticket,
  onTicketUpdate,
}) => {
  const { locator } = useParams<Record<string, string>>();
  const { user } = useAuth();

  const handover: MerchandiseHandover | undefined =
    ticket.merchandiseHandovers.find((mh) => mh.merchandiseItemId === item.id);

  const id = `merchandise-input-${ticket.id}-${item.id}`;

  const toggleItem = async () => {
    if (handover && !handover.deletedAt) {
      const confirmed = window.confirm(
        `Are you sure you want to uncheck "${item.name}"?`
      );
      if (!confirmed) {
        return;
      }
      const updated = await removeHandover(locator, item, ticket);
      onTicketUpdate(updated);
    } else {
      const updated = await handOverItem(locator, item, ticket, user.id);
      onTicketUpdate(updated);
    }
  };

  return (
    <div
      className="form-group form-check"
      onClickCapture={(e) => e.stopPropagation()}
    >
      <input
        className="form-check-input"
        type="checkbox"
        checked={!!handover && !handover.deletedAt}
        onChange={toggleItem}
        id={id}
      />
      <label htmlFor={id} className="form-check-label">
        {item.name}
      </label>
    </div>
  );
};

export default Merchandise;
