import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Color from 'models/Color';
import TicketPurchase from 'models/TicketPurchase';
import './styles.scss';

interface CheckInStatsProps {
  selectedCategory: string;
  setSelectedCategory: (type: string) => void;
  purchases?: TicketPurchase[];
}

interface EventCheckInStats {
  eventId?: number;
  scheduleItemId?: number;
  ticketPackageId: number;
  ticketPackageName: string;
  ticketPackageColor: Color;
  ticketsCount: number;
  checkedInCount: number;
}

const CheckInStats = ({
  selectedCategory,
  setSelectedCategory,
  purchases,
}: CheckInStatsProps) => {
  const [stats, setStats] = useState<EventCheckInStats[]>([]);
  const [totalCheckedInCount, setTotalCheckedInCount] = useState(0);
  const [totalTicketCount, setTotalTicketCount] = useState(0);

  const clickTicketPackage = (clicked: string) => {
    if (selectedCategory === clicked) {
      setSelectedCategory('');
    } else {
      setSelectedCategory(clicked);
    }
  };

  useEffect(() => {
    if (!purchases || !Array.isArray(purchases) || purchases.length < 1) return;

    const statsMap = new Map<number, EventCheckInStats>();
    const sortedPurchases = purchases.sort((a, b) => (a.id > b.id ? 1 : -1));

    // Scan the ticket purchases for ticket packages
    sortedPurchases
      .map((p) => p.ticketPackage)
      .forEach((tp) => {
        if (!statsMap.has(tp.id)) {
          statsMap.set(tp.id, {
            ticketPackageId: tp.id,
            ticketPackageName: tp.name,
            ticketsCount: 0,
            checkedInCount: 0,
            ticketPackageColor: new Color(tp.color),
          });
        }
      });

    // Now tally up the ticket check-ins
    sortedPurchases.forEach((p) => {
      p.tickets.forEach((t) => {
        const s = statsMap.get(p.ticketPackage.id);
        if (s) {
          s.ticketsCount++;
          s.checkedInCount += t.checkedIn ? 1 : 0;
        }
      });
    });
    setStats(Array.from(statsMap.values()));
  }, [purchases]);

  useEffect(() => {
    setTotalCheckedInCount(
      stats.reduce((total, s) => s.checkedInCount + total, 0)
    );
    setTotalTicketCount(stats.reduce((total, s) => s.ticketsCount + total, 0));
  }, [stats]);

  return (
    <div className="CheckInStats">
      <div className="d-none d-md-block text-center">Packages</div>
      {stats.map((s: any) => (
        <div
          className={classNames('PackageCheckIn', {
            'PackageCheckIn--inactive':
              selectedCategory && selectedCategory !== s.ticketPackageName,
          })}
          key={s.ticketPackageId}
          id={s.ticketPackageId}
          onClick={() => clickTicketPackage(s.ticketPackageName)}
          style={{
            backgroundColor: s.ticketPackageColor.hex,
            color: s.ticketPackageColor.textColor,
          }}
        >
          <div className="PackageCheckIn__Title d-none d-md-block">
            {s.ticketPackageName}
          </div>
          <div className="PackageCheckIn__Count">
            {s.checkedInCount} / {s.ticketsCount}
          </div>
          <div className="PackageCheckIn__Percent">
            {Math.floor((s.checkedInCount / s.ticketsCount) * 100)}%
          </div>
        </div>
      ))}
      {totalTicketCount !== 0 && (
        <div
          className={classNames('PackageCheckIn', {
            'PackageCheckIn--inactive':
              selectedCategory && selectedCategory !== '',
          })}
          onClick={() => clickTicketPackage('')}
          style={{ backgroundColor: '#ffffff', color: '#000000' }}
        >
          <div className="PackageCheckIn__Title d-none d-md-block">
            {'All Tickets'}
          </div>
          <div className="PackageCheckIn__Count">
            {totalCheckedInCount} / {totalTicketCount}
          </div>
          <div className="PackageCheckIn__Percent">
            {Math.floor((totalCheckedInCount / totalTicketCount) * 100)}%
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckInStats;
