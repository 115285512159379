import React from 'react';
import classNames from 'classnames';

interface GuestTicketProps {
  checkedIn: boolean;
}

const GuestTicket: React.FunctionComponent<GuestTicketProps> = ({
  checkedIn,
}) => {
  const className = classNames('GuestTicket pl-1 pl-sm-0 px-sm-1', {
    'GuestTicket--checkedIn': checkedIn,
    'text-secondary': !checkedIn,
    'text-success': checkedIn,
  });

  // Circle is unicode U+25CF
  // Hollow circle is unicode U+25CB
  const content = checkedIn ? '●' : '○';

  return <span className={className}>{content}</span>;
};

export default GuestTicket;
