import React from 'react';
import classNames from 'classnames';
import LoadingSpinner from 'components/core/LoadingSpinner';
import useServiceWorker from 'hooks/useServiceWorker';
import useSyncState from 'hooks/useSyncState';
import {
  WorkerMessage,
  WorkerMessageType,
} from 'providers/ServiceWorkerProvider';
import { SyncStateProviderType } from 'providers/SyncStateProvider';
import './styles.scss';

const SyncStatusIndicator: React.FunctionComponent = () => {
  const { wb } = useServiceWorker();
  const { syncing, pending }: SyncStateProviderType = useSyncState();

  const syncUpdates = () => {
    wb?.messageSW(WorkerMessage(WorkerMessageType.FlushUpdates));
  };

  return (
    <div
      onClick={syncUpdates}
      className={classNames(
        'SyncStatusIndicator d-flex flex-row align-items-center justify-content-between',
        {
          'SyncStatusIndicator--syncing': syncing,
          'SyncStatusIndicator--pending': pending,
        }
      )}
    >
      {syncing ? (
        <div className="SyncStatusIndicator__TextStatus">
          <span className="d-none d-md-inline">Syncing</span>
          <LoadingSpinner />
        </div>
      ) : (
        <div className="SyncStatusIndicator__TextStatus">
          {pending ? <span className="d-none d-md-inline">Pending</span> : '✓'}
        </div>
      )}

      {pending > 0 && (
        <div className="SyncStatusIndicator__VisualStatus">
          <span className="d-none d-md-inline">&nbsp;</span>
          {pending}
        </div>
      )}
    </div>
  );
};

export default SyncStatusIndicator;
