import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { createNote } from 'api';
import Form from 'components/core/Form';
import TicketPurchase from 'models/TicketPurchase';
import { formatDate } from 'utils';
import { useAuth } from 'providers/AuthProvider';

interface NewNoteProps {
  ticketPurchase: TicketPurchase;
  onNoteAdded: (update: TicketPurchase) => void;
}

const NewNote: React.FunctionComponent<NewNoteProps> = ({
  ticketPurchase,
  onNoteAdded,
}) => {
  const [content, setContent] = useState('');
  const { locator } = useParams<Record<string, string>>();
  const { user: currentUser } = useAuth();

  const addNote = async () => {
    const newNote = {
      ticketPurchaseId: ticketPurchase.id,
      userId: currentUser.id,
      content: content,
      createdAt: new Date().toJSON(),
      updatedAt: new Date().toJSON(),
    };

    const update = await createNote(locator, ticketPurchase, newNote);
    update.notes[0].user = currentUser;

    setContent('');
    onNoteAdded(update);
  };

  return (
    <Form className="NewNote" onSubmit={addNote}>
      <div className="form-group">
        <div className="NewNote__User mb-2">
          <span className="text-primary">{currentUser.email}</span>{' '}
          <span className="text-muted">{formatDate(new Date())}</span>
        </div>
        <label htmlFor="newNoteContent" className="visually-hidden">
          New note
        </label>
        <textarea
          id="newNoteContent"
          autoFocus={true}
          className="NewNote__TextArea form-input-area"
          placeholder="Add a new note"
          rows={6}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
      <div className="float-end">
        <button
          disabled={content === ''}
          className="btn btn-primary pull-right"
        >
          Add note
        </button>
      </div>
    </Form>
  );
};

export default NewNote;
