import React from 'react';
import ScheduleItemSelector from 'components/ScheduleItemSelector';
import UserMenu from 'components/UserMenu';
import EventListLayout from 'layout/EventListLayout';
import { useAuth } from 'providers/AuthProvider';

const ChooseEventPage = () => {
  const { user } = useAuth();
  if (!user) {
    return null;
  }

  const rightContent = <UserMenu eventList={false} />;
  return (
    <EventListLayout rightContent={rightContent}>
      <div className="row">
        <div className="col-12 col-sm-6 offset-sm-3 bg-white p-3">
          <ScheduleItemSelector />
        </div>
      </div>
    </EventListLayout>
  );
};

export default ChooseEventPage;
