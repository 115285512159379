import React from 'react';
import { Link } from 'react-router-dom';
import GuestListBar from 'components/GuestListBar';
import LoggedInLayout from 'layout/LoggedInLayout';
import { useAuth } from 'providers/AuthProvider';
import ticketScanPath from 'images/ticket-scan.png';

const ScannerPage: React.FunctionComponent = () => {
  const { user } = useAuth();
  if (!user) {
    return null;
  }

  return (
    <LoggedInLayout>
      <div className="ScannerPage my-md-5 d-flex flex-column justify-content-center">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <div className="ScannerPage__Confirmation bg-white border rounded text-center flex-column justify-content-between">
              <Link to="/confirmation">
                <img
                  src={ticketScanPath}
                  className="img-fluid"
                  alt="Ticket scan"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <GuestListBar />
    </LoggedInLayout>
  );
};

export default ScannerPage;
