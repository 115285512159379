import { DBSchema, IDBPDatabase, openDB } from 'idb';
import { mergeObjects, UniqueIdKeyMapType } from 'utils';

const CACHE_VERSION = 1;
const API_CACHE = `API-CACHE-${CACHE_VERSION}`;
const uniqueIdKeyMap: UniqueIdKeyMapType = {
  merchandiseHandovers: 'merchandiseItemId',
};
interface PendingChange {
  method: string;
  body: string;
}

interface PendingChanges {
  key: string;
  value: PendingChange[];
}

// extending EventTarget so ServiceWorker can observe updates and pass along to Windows
interface CacheStoreSchema extends DBSchema {
  'pending-changes': PendingChanges;
}

// TODO: add timeout to fetch requests

export class CacheStore extends EventTarget {
  _db?: IDBPDatabase<CacheStoreSchema>;
  flushing = false;
  // exposing as attribute to minimize async react code
  pending = 0;
  // using to indicate the db is initialized in Window context
  // we can't get pending until indexeddb is initialized
  initResolve!: (value: boolean | PromiseLike<boolean>) => void;
  initReject!: (value: boolean | PromiseLike<boolean>) => void;
  initialized: Promise<boolean>;

  constructor() {
    super();
    this.initialized = new Promise<boolean>((resolve, reject) => {
      this.initResolve = resolve;
      this.initReject = reject;
    });
  }

  // used to signal the serviceworker that something has changed
  // which in turn sends a 'syncstate' ServiceWorkerMessage to any open Windows
  async syncState() {
    this.pending = await this.pendingCount();
    this.dispatchEvent(
      new CustomEvent('syncstate', {
        detail: {
          syncing: this.flushing,
          pending: this.pending,
        },
      })
    );
  }

  async connect() {
    this._db = await openDB<CacheStoreSchema>('cache-store', 1, {
      upgrade(db, oldVersion) {
        if (oldVersion < 1) {
          db.createObjectStore('pending-changes');
        }
      },
    });
    this.pending = await this.pendingCount();
    this.initResolve(true);
  }

  async perform(request: Request) {
    try {
      const method = request.method;
      if (method !== 'GET' && method !== 'HEAD') {
        await this.flush();
      }
    } catch (_) {
      // do nothing
    }
    return this.doRequest(request);
  }

  async doRequest(request: Request) {
    let result: Response;
    const method = request.method;

    if (method === 'GET' || method === 'HEAD') {
      result = await this.get(request);
    } else if (method === 'PUT' || method === 'PATCH') {
      result = await this.update(request);
    } else {
      result = await fetch(request);
    }

    return result;
  }

  async get(request: Request) {
    const cache = await caches.open(API_CACHE);
    let response = await cache.match(request);

    if (response) {
      // we don't await for the fresh response
      cache.add(request);
    } else {
      response = await fetch(request);
      await cache.put(request, response.clone());
    }

    return response;
  }

  async update(request: Request) {
    const cache = await caches.open(API_CACHE);
    const cachedGet = new Request(request.url, { method: 'GET' });
    const existing = (await this.db.get('pending-changes', request.url)) || [];

    try {
      if (existing.length > 0) {
        throw new Error("CacheStore hasn't been flushed");
      }

      const response = await fetch(request.clone());

      if (response.ok) {
        await cache.put(cachedGet, response.clone());
      } else {
        if ([504].includes(response.status)) {
          console.log('Server error', response);
          throw new Error('Server error');
        }
      }

      return response;
    } catch (error) {
      // Update failed, return cache with updates
      const update = await request.clone().text();
      await this.db.put(
        'pending-changes',
        [
          ...existing,
          {
            method: request.method,
            body: update,
          },
        ],
        request.url
      );

      let cachedResponse = await cache.match(cachedGet);
      if (cachedResponse) {
        try {
          let model = await cachedResponse.clone().json();
          model = mergeObjects(model, JSON.parse(update), uniqueIdKeyMap);
          cachedResponse = new Response(JSON.stringify(model), {
            headers: { 'Content-Type': 'application/json' },
          });

          await cache.put(cachedGet, cachedResponse.clone());
        } catch (error) {
          console.error('Unable to parse update JSON', error);
        }
      }

      this.syncState();
      return cachedResponse;
    }
  }

  async pendingCount() {
    const urls = await this.db.getAll('pending-changes');
    return urls.reduce((sum, array) => sum + array.length, 0);
  }

  // used by Window thread to verify db is initialized
  // before checking/rendering initial pending state
  async isInitialized() {
    return await this.initialized;
  }

  async flush() {
    if (this.flushing) return;
    if (!navigator.onLine) return;

    const nPendingChanges = await this.pendingCount();
    if (nPendingChanges === 0) return;

    console.info(`[DB] Flushing ${nPendingChanges} changes`);
    this.flushing = true;
    this.syncState();
    try {
      const urls: string[] = await this.db.getAllKeys('pending-changes');
      const promises = urls
        .map((url) => this.flushChanges(url))
        .filter((p) => p != null);
      await Promise.all(promises);
    } finally {
      console.info(`[DB] Flushing complete`);
      this.flushing = false;
      this.syncState();
    }
  }

  async flushChanges(url: string) {
    const changes = (await this.db.get('pending-changes', url)) || [];

    // TODO: flushChanges should send the whole array instead of sending multiple requests

    const failed: PendingChange[] = [];
    const promises = changes
      .map((change) =>
        this.flushChange(url, change).then((response) => {
          if (response && !response.ok) {
            failed.push(change);
          }
        })
      )
      .filter((p) => !!p);

    return Promise.all(promises).finally(() => {
      if (failed.length === 0) {
        this.db.delete('pending-changes', url);
      } else {
        this.db.put('pending-changes', failed, url);
      }
    });
  }

  async flushChange(url: string, pc: PendingChange) {
    if (!pc) return;

    const request = new Request(url, pc);
    const response = await fetch(request);

    this.syncState();
    return response;
  }

  async clear() {
    await this.db.clear('pending-changes');
    await caches.delete(API_CACHE);
  }

  get db() {
    if (!this._db) {
      throw new Error('Attempt to access DB before connecting');
    }
    return this._db;
  }
}

const cacheStore = new CacheStore();
cacheStore.connect();

export default cacheStore;
