import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getTicketPurchase } from 'api';
import classNames from 'classnames';
import LoadingSpinner from 'components/core/LoadingSpinner';
import NotesBadge from 'components/NotesBadge';
import PurchaseDetails from 'components/PurchaseDetails';
import TicketList from 'components/TicketList';
import TicketPageSection from 'components/TicketPageSection';
import {
  CurrentScheduleItemProps,
  withCurrentScheduleItem,
} from 'components/withCurrentScheduleItem';
import LoggedInLayout from 'layout/LoggedInLayout';
import TicketPurchase, { fullName, hasNote } from 'models/TicketPurchase';
import { guestListPath, notesPath } from 'Routing';
import { useAuth } from 'providers/AuthProvider';

interface TicketPageParams {
  [key: string]: string;
  locator: string;
  scheduleItemId: string;
  purchaseId: string;
}

type TicketPageProps = TicketPageParams & CurrentScheduleItemProps;

const TicketPage = ({ currentEvent, currentScheduleItem }: TicketPageProps) => {
  const { purchaseId } = useParams<TicketPageParams>();

  const { data: purchase } = useSWR(
    [
      'ticket-purchase',
      currentEvent.locator,
      currentScheduleItem.id,
      purchaseId,
    ],
    () =>
      getTicketPurchase(
        currentEvent.locator,
        currentScheduleItem.id,
        purchaseId
      )
  );

  const { user } = useAuth();
  if (!user) {
    return null;
  }

  const doneLink = (
    <Link
      className="btn btn-primary"
      to={guestListPath(
        currentEvent.locator,
        '' + (currentScheduleItem.id || '')
      )}
    >
      Done
    </Link>
  );

  if (!currentEvent || !currentScheduleItem || !purchase) {
    return <LoadingSpinner />;
  }

  return (
    <LoggedInLayout
      currentEvent={currentEvent}
      currentScheduleItem={currentScheduleItem}
      headerLeftContent={doneLink}
    >
      <div className="TicketPage">
        <TicketPageHeader
          ticketPurchase={purchase}
          currentEvent={currentEvent}
          currentScheduleItem={currentScheduleItem}
        />
        <TicketPageSection title="Tickets">
          <TicketList ticketPurchase={purchase} />
        </TicketPageSection>

        <TicketPageSection title="Purchase details">
          <PurchaseDetails
            event={currentEvent}
            scheduleItem={currentScheduleItem}
            ticketPurchase={purchase}
          />
        </TicketPageSection>
      </div>
    </LoggedInLayout>
  );
};

interface TicketPurchaseProps extends CurrentScheduleItemProps {
  ticketPurchase: TicketPurchase;
}

const TicketPageHeader: React.FunctionComponent<TicketPurchaseProps> = ({
  ticketPurchase,
  currentEvent,
  currentScheduleItem,
}) => {
  const notesHref = notesPath(
    currentEvent.locator,
    currentScheduleItem.id,
    ticketPurchase.id
  );

  const hasNotes = hasNote(ticketPurchase);
  const linkClasses = classNames('btn', {
    'btn-outline-secondary': !hasNotes,
    'btn-outline-primary': hasNotes,
  });

  return (
    <div className="TicketPage__Header px-1 px-md-0">
      <div className="row">
        <div className="col-8">
          <h1 className="h5">{fullName(ticketPurchase)}</h1>
        </div>
        <div className="col-4 text-end">
          <Link className={linkClasses} to={notesHref}>
            Notes <NotesBadge ticketPurchase={ticketPurchase} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withCurrentScheduleItem(TicketPage);
