import { useContext } from 'react';
import {
  ServiceWorkerContext,
  ServiceWorkerProviderType,
} from 'providers/ServiceWorkerProvider';

export default function useServiceWorker(): ServiceWorkerProviderType {
  const context = useContext(ServiceWorkerContext);
  if (!context) {
    throw new Error(
      'useServiceWorker can only be used within a ServiceWorkerProvider'
    );
  }
  return context;
}
