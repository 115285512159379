import logoPathimage from 'images/logo-dark.svg';
export default interface Event {
  id: number;
  locator: string;
  name: string;
  graphic: string;
}

const imageHost = process.env.IMAGE_HOST || '/images';

export const logoPath = (event: Event, height = 40) => {
  if (!event.graphic) {
    return logoPathimage;
  }
  return imageHost + `/resized/${event.graphic}?h=${height}&m=fit&format=png`;
};
