import React, { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/browser';

class ApplicationError extends React.Component<PropsWithChildren> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    return (
      <div className="ApplicationError h-100 text-center d-flex align-items-center justify-content-center">
        <div className="ApplicationError__Message">
          <h1>That&rsquo;s not right&hellip;</h1>
          <p>We seem to have encountered an error.</p>

          <p>
            If refreshing the page doesn&rsquo;t help, please shoot us a note at{' '}
            <a href="mailto:hello@stagepilot.com">hello@stagepilot.com</a>.
          </p>
        </div>
      </div>
    );
  }
}

export default ApplicationError;
