import React from 'react';

import Alert from 'components/core/Alert';
import useOnline from 'hooks/useOnline';

function ConnectionIndicator() {
  const online = useOnline();

  if (online) return null;

  return (
    <div className="ConnectionIndicator">
      <Alert type="dark">
        <p>No internet connection. </p>
        <p>Keep working and all changes will sync when you’re back online.</p>
      </Alert>
    </div>
  );
}

export default ConnectionIndicator;
