import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEvents, getScheduleItems } from 'api';
import useSessionState from 'hooks/useSessionState';
import Event from 'models/Event';
import ScheduleItem, {
  fullDescription,
  isFutureScheduleItem,
} from 'models/ScheduleItem';
import Form from './core/Form';
import { useAuth } from 'providers/AuthProvider';

export default function ScheduleItemSelector() {
  const navigate = useNavigate();
  const [events, setEvents] = useState<Event[]>([]);
  const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([]);
  const [selectedEvent, setSelectedEvent] = useSessionState<Event | undefined>(
    'selectedEvent',
    undefined
  );
  const [selectedScheduleItem, setSelectedScheduleItem] = useSessionState<
    ScheduleItem | undefined
  >('selectedScheduleItem', undefined);

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const loadEvents = async () => {
        const events = await getEvents();
        setEvents(events);

        if (events) {
          console.log('loaded?', selectedEvent, events);
          setSelectedEvent(selectedEvent || events[0]);
        }
      };

      loadEvents();
    }
  }, [user]);

  useEffect(() => {
    const loadScheduleItems = async () => {
      setScheduleItems([]);

      if (!selectedEvent) {
        return;
      }

      const scheduleItems = await getScheduleItems(selectedEvent.locator);

      const nextScheduleItem =
        scheduleItems.find(({ id }) => selectedScheduleItem?.id === id) ||
        scheduleItems.find((si) => isFutureScheduleItem(si)) ||
        scheduleItems[scheduleItems.length - 1];

      setScheduleItems(scheduleItems);
      setSelectedScheduleItem(nextScheduleItem);
    };

    loadScheduleItems();
  }, [selectedEvent]);

  const handleEventChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedEvent = events.find(
      (event) => event.id.toString() === e.target.value
    );

    setSelectedEvent(selectedEvent);
  };

  const handleScheduleItemChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedScheduleItem = scheduleItems.find(
      (si) => si.id.toString() === e.target.value
    );

    setSelectedScheduleItem(selectedScheduleItem);
  };

  const startCheckIn = () => {
    const locator = selectedEvent?.locator;
    const scheduleItemId = selectedScheduleItem?.id;

    navigate(`/${locator}/${scheduleItemId}/guests`);
  };

  if (!user) {
    return <div>Not authenticated</div>;
  }

  return (
    <div className="ScheduleItemSelector">
      <Form className="form-signin" onSubmit={startCheckIn}>
        <h1 className="h5 mb-4 font-weight-normal text-center">
          Choose your event and date
        </h1>

        <div className="form-group">
          <label htmlFor="inputEvent">Event</label>
          <select
            id="inputEvent"
            data-testid="event_selector"
            className="form-select"
            onChange={handleEventChange}
            value={selectedEvent?.id}
          >
            {events.map((e) => (
              <option value={e.id} key={`event-${e.id}`}>
                {e.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="inputItem">Date</label>
          <select
            id="inputItem"
            data-testid="schedule_item_selector"
            className="form-select"
            onChange={handleScheduleItemChange}
            value={selectedScheduleItem?.id}
          >
            {scheduleItems.map((si) => (
              <option value={si.id} key={`item-${si.id}`}>
                {fullDescription(si)}
              </option>
            ))}
          </select>
        </div>

        <button
          className="btn btn-lg btn-primary w-100"
          disabled={!selectedScheduleItem}
        >
          Start check-in
        </button>
      </Form>
    </div>
  );
}
