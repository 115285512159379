import classNames from 'classnames';
import React from 'react';

interface State {
  validated: boolean;
}

export default class Form extends React.Component<
  React.HTMLProps<HTMLFormElement>,
  State
> {
  state = {
    validated: false,
  };

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!(e.target as HTMLFormElement).checkValidity()) {
      this.setState({ validated: true });
      return;
    }

    this.setState({ validated: false }, () => {
      this.props.onSubmit && this.props.onSubmit(e);
    });
  };

  render() {
    const className = classNames(this.props.className, {
      'was-validated': this.state.validated,
    });

    return (
      <form className={className} onSubmit={this.onSubmit} noValidate>
        {this.props.children}
      </form>
    );
  }
}
