import User from 'models/User';
import { formatDate } from 'utils';

export default interface Note {
  id?: number;
  ticketPurchaseId: number;
  userId: number;
  user: User;
  content: string;
  createdAt: string;
  updatedAt: string;
}

export interface NoteUpdate {
  id?: number;
  ticketPurchaseId: number;
  userId: number;
  content: string;
  createdAt?: string;
  updatedAt: string;
}

export const time = (n: Note) => {
  return formatDate(new Date(n.createdAt));
};
