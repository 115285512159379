import React, { useEffect, useState } from 'react';
import { getEvents, getScheduleItem } from 'api';
import LoadingSpinner from 'components/core/LoadingSpinner';
import Event from 'models/Event';
import ScheduleItem from 'models/ScheduleItem';
import { useParams } from 'react-router-dom';

export interface CurrentScheduleItemProps {
  currentEvent: Event;
  currentScheduleItem: ScheduleItem;
}

export interface CurrentScheduleItemRouteParams {
  locator: string;
  scheduleItemId: string;
}

export function withCurrentScheduleItem<T>(Component: React.FC<T>) {
  function WrappedComponent(props: any) {
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [currentScheduleItem, setCurrentScheduledItem] =
      useState<ScheduleItem>();
    const { locator, scheduleItemId } = useParams();

    useEffect(() => {
      const sid = parseInt(scheduleItemId ?? '');

      async function getData() {
        const [events, item] = await Promise.all([
          getEvents(),
          getScheduleItem(locator, sid),
        ]);

        const event = events.find((e) => e.locator === locator);
        setCurrentEvent(event);
        setCurrentScheduledItem(item);
      }
      getData();
    }, []);

    if (!currentEvent || !currentScheduleItem) {
      return <LoadingSpinner />;
    }

    return (
      <Component
        {...props}
        currentEvent={currentEvent}
        currentScheduleItem={currentScheduleItem}
      />
    );
  }
  WrappedComponent.displayName =
    (Component.displayName || Component.name || 'Component') +
    'WithCurrentScheduleItem';
  return WrappedComponent;
}
