import React from 'react';
import Guest from 'components/Guest';
import TicketPurchase from 'models/TicketPurchase';
import './styles.scss';

interface Props {
  purchases: TicketPurchase[];
  checkInTicketPurchase: (ticketPurchase: TicketPurchase) => Promise<any>;
}

const GuestList: React.FunctionComponent<Props> = ({
  purchases,
  checkInTicketPurchase,
}) => {
  const sortedPurchases = purchases.sort((a, b) =>
    a.lastName > b.lastName ? 1 : -1
  );

  return (
    <div className="GuestList scroll-auto">
      {sortedPurchases.map((tp) => (
        <Guest
          key={tp.id}
          ticketPurchase={tp}
          checkInTicketPurchase={checkInTicketPurchase}
        />
      ))}
    </div>
  );
};

export default GuestList;
