import { useEffect, useState } from 'react';
import useServiceWorker from 'hooks/useServiceWorker';
import { WorkboxMessageEvent } from 'workbox-window/utils/WorkboxEvent';

export default function useServiceWorkerMessage<T>(messageType: string) {
  const { wb } = useServiceWorker();
  const [messageData, setMessageData] = useState<T>();

  const handleMessageData = (event: WorkboxMessageEvent) => {
    if (event?.data?.type === messageType) {
      setMessageData(event.data.data);
    }
  };

  useEffect(() => {
    wb?.addEventListener('message', handleMessageData);
    return () => {
      wb?.removeEventListener('message', handleMessageData);
    };
  });

  return messageData;
}
