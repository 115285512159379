import React from 'react';

interface TicketPageSectionProps {
  title: string;
  children: React.ReactNode;
}

const TicketPageSection: React.FunctionComponent<TicketPageSectionProps> = ({
  title,
  children,
}) => {
  return (
    <div className="TicketPageSection bg-white row py-3 my-3">
      <div className="col-12">
        <h3 className="TicketPageSection__Title h6 mb-4 text-secondary font-weight-light text-uppercase">
          {title}
        </h3>
        {children}
      </div>
    </div>
  );
};

export default TicketPageSection;
