export default interface ScheduleItem {
  id: number;
  location: string;
  description: string;
  startsAt: string;
  time: string;
}

export const fullDescription = (si: ScheduleItem) => {
  return [si.location, si.startsAt, si.time].join(' ');
};

export const isFutureScheduleItem = (si: ScheduleItem) => {
  const start = startOfDay(new Date(si.startsAt));
  const now = startOfDay(new Date());
  return start >= now;
};

const startOfDay = (dt: Date) =>
  new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
