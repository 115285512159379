import React from 'react';

import './LoadingSpinner.scss';

const LoadingSpinner: React.FunctionComponent = () => {
  return (
    <div className="LoadingSpinner text-center">
      <div className="text-primary spinner-border m-5" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingSpinner;
