import React from 'react';

interface Props {
  messages: string[];
}

const ErrorMessage: React.FunctionComponent<Props> = ({ messages }) => {
  messages = messages.filter((m) => !!m);

  if (messages.length < 1) return null;

  return (
    <div className="alert alert-danger" role="alert">
      {messages.map((m, ix) => (
        <p key={ix}>{m}</p>
      ))}
    </div>
  );
};

export default ErrorMessage;
