import MerchandiseHandover from 'models/MerchandiseHandover';

export default interface Ticket {
  id: number;
  ticketPurchaseId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  name?: string;
  checkedIn: boolean;
  merchandiseHandovers: MerchandiseHandover[];
  ticketChanges?: {
    id: number;
    checkedIn: boolean;
    userID: number;
    ticketID: number;
  }[];
}

export const getHandoversFromTicket = (ticket: Ticket) => {
  return {
    id: ticket.id,
    merchandiseHandovers: ticket.merchandiseHandovers,
  } as Ticket;
};

// TODO: make this structured data in the API
export const seat = (ticket: Ticket) => {
  const parts = ticket.name.trim().split(' ');
  return parts[parts.length - 1];
};

// adding either creates or "un-deletes" existing
// undeletes happen in offline mode
export const addHandoverToTicket = (
  ticket: Ticket,
  merchandiseItemId: number,
  currentUserId: number
) => {
  let handover = ticket.merchandiseHandovers.find(
    (mh) => mh.merchandiseItemId === merchandiseItemId
  );

  if (handover) {
    // in case this was previously deleted offline and we haven't synced yet
    // remove deletedAt in a mergeable way
    handover.deletedAt = undefined;
    return;
  }

  handover = {
    userId: currentUserId,
    ticketPurchaseId: ticket.ticketPurchaseId,
    merchandiseItemId: merchandiseItemId,
    ticketId: ticket.id,
  };

  ticket.merchandiseHandovers.push(handover);
};

export const removeHandoverFromTicket = (
  ticket: Ticket,
  merchandiseItemId: number
) => {
  const found = ticket.merchandiseHandovers.find(
    (mh) => mh.merchandiseItemId === merchandiseItemId
  );

  if (found) {
    found.deletedAt = new Date();
  }
};
