import React from 'react';

const DEFAULT_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const formatDate = (d: Date) => {
  return Intl.DateTimeFormat('en-US', DEFAULT_FORMAT).format(d);
};

export const simpleFormat = (text?: string) => {
  if (!text) {
    return null;
  }
  return text.split('\n').map((para, idx) => <p key={idx}>{para}</p>);
};

export interface UniqueIdKeyMapType {
  [key: string]: string;
}

/**
 * mergeObjects takes two objects and does a recursive merge that doesn't
 * clobber attributes that don't exist in the update.
 *
 * @export
 * @template T
 * @param {T} model the base model object that updates are merged into
 * @param {T} update the updates to be merged into the base model object
 * @param {UniqueIdKeyMapType} [uniqueIdKeyMap] optional and is passed down recursively in case it's needed for merging deeper Arrays
 * @returns the merged object
 */
export function mergeObjects<T extends Record<string, any>>(
  model: T,
  update: T,
  uniqueIdKeyMap?: UniqueIdKeyMapType
) {
  const merged: T = { ...model };
  const keys: string[] = Object.keys(update);
  keys.forEach((key) => {
    const updated = update[key];
    if (merged[key] && updated === Object(updated)) {
      if (Array.isArray(updated)) {
        (merged as object)[key] = mergeArrays<T>(
          merged[key],
          updated,
          key,
          uniqueIdKeyMap
        );
      } else {
        (merged as object)[key] = mergeObjects<T>(
          merged[key],
          updated,
          uniqueIdKeyMap
        );
      }
    } else {
      (merged as object)[key] = updated;
    }
  });

  return merged;
}

/**
 * mergeArrays takes two arrays and does a recursive merge if objects IDs match
 * and inserts new entries at the end of the array.
 *
 * @export
 * @template T
 * @param {T[]} models the base models array that updates are merged into
 * @param {T[]} updates the updates array to be merged into the base model objects
 * @param {string} [parentKey=''] optional and used in conjunction with the uniqueIdKeyMap to
 * @param {UniqueIdKeyMapType} [uniqueIdKeyMap] optional and used to provide an alternate identifier
 * for the models in the Array, when the default 'id' attribute isn't sufficient
 * @returns
 */
export function mergeArrays<T extends Record<string, any>>(
  models: T[],
  updates: T[],
  parentKey = '',
  uniqueIdKeyMap?: UniqueIdKeyMapType
) {
  const merged: T[] = [...models];
  updates.forEach((update) => {
    const idKey = uniqueIdKeyMap?.[parentKey] || 'id';
    const idx = merged
      .filter(({ [idKey]: id }) => id >= 0)
      .findIndex(({ [idKey]: id }) => id === update[idKey]);

    if (!!update[idKey] && idx >= 0) {
      merged[idx] = mergeObjects(merged[idx], update, uniqueIdKeyMap);
    } else {
      merged.push(update);
    }
  });

  return merged;
}
