import React, { useEffect } from 'react';
import GlobalError from 'components/GlobalError';
import ScheduleItem from 'models/ScheduleItem';
import Event from 'models/Event';
import LoggedInHeader from './LoggedInHeader';

interface Props {
  currentEvent?: Event;
  currentScheduleItem?: ScheduleItem;
  stickyHeader?: boolean;
  headerLeftContent?: React.ReactNode;
  headerRightContent?: React.ReactNode;
  children: React.ReactNode;
}

const LoggedInLayout: React.FunctionComponent<Props> = ({
  currentEvent,
  currentScheduleItem,
  stickyHeader = true,
  headerLeftContent,
  children,
}) => {
  useEffect(() => {
    document.body.classList.add('LoggedIn');
    document.body.classList.remove('LoggedOut', 'bg-dark');
  });

  return (
    <div className="LoggedInLayout">
      <GlobalError />
      <LoggedInHeader
        currentEvent={currentEvent}
        currentScheduleItem={currentScheduleItem}
        stickyHeader={stickyHeader}
        leftContent={headerLeftContent}
      />

      <div className="container py-3 h-100">{children}</div>
    </div>
  );
};

export default LoggedInLayout;
