import React from 'react';
import emptyPath from 'images/Empty.svg';

interface Props {
  message: string;
}

const Empty: React.FunctionComponent<Props> = ({ message }) => {
  return (
    <div className="Empty my-5 text-center">
      <img alt="Empty" className="m-3" src={emptyPath} />

      <div className="Empty__Message">{message}</div>
    </div>
  );
};

export default Empty;
