import React from 'react';
import Note, { time } from 'models/Note';
import { simpleFormat } from 'utils';

interface NoteProps {
  note: Note;
}

const NoteCard: React.FunctionComponent<NoteProps> = ({ note }) => {
  const user = note.user || { email: '' };

  const content = simpleFormat(note.content);

  return (
    <div className="Note my-3" id={`note_${note.id}`}>
      <div className="Note__Header mb-2">
        <span className="text-primary">{user.email}</span>{' '}
        <span className="text-muted">{time(note)}</span>{' '}
      </div>
      <div className="border shadow-sm bg-white rounded p-3">{content}</div>
    </div>
  );
};

export default NoteCard;
