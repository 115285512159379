declare let GIT_VERSION: string;
declare let SERVICE_WORKER_DISABLED: string;

import React, { createContext, useEffect, useState, useMemo } from 'react';
import { Workbox } from 'workbox-window';

export enum WorkerMessageType {
  Ping = 'PING',
  SkipWaiting = 'SKIP_WAITING',
  VersionCheck = 'VERSION_CHECK',
  FlushUpdates = 'FLUSH_UPDATES',
}

export function WorkerMessage(type: WorkerMessageType, data: object = {}) {
  return {
    ...data,
    type,
  };
}

export interface ServiceWorkerProviderType {
  wb?: Workbox;
}

export const ServiceWorkerContext = createContext<ServiceWorkerProviderType>(
  null!
);

interface Props {
  children: React.ReactNode;
}

export async function registerServiceWorker(): Promise<Workbox | undefined> {
  if (!('serviceWorker' in navigator)) {
    console.error('No service worker support');
    return undefined;
  }
  const wb = new Workbox('/service-worker.js');

  // Add an event listener to detect when the registered
  // service worker has installed but is waiting to activate.
  wb.addEventListener('waiting', () => {
    console.info(`[SW] Version ${GIT_VERSION} installing`);

    wb.addEventListener('controlling', (event) => {
      console.info(
        `[SW] Version ${GIT_VERSION} installed, should reload`,
        event
      );
    });
  });

  wb.addEventListener('activated', () => {
    console.info(`[SW] Version ${GIT_VERSION} activated`);
  });

  try {
    await wb.register();
    console.info(`[SW] Version ${GIT_VERSION} registered`);
    return wb;
  } catch (e) {
    console.error('[SW] registration failed:', e);
    return undefined;
  }
}

export default function ServiceWorkerProvider({ children }: Props) {
  const [wb, setWb] = useState<Workbox>();
  const disableServiceWorker = JSON.parse(SERVICE_WORKER_DISABLED);

  useEffect(() => {
    if (!wb && !disableServiceWorker) {
      registerServiceWorker().then(setWb);
    }
  }, [wb]);

  const value: ServiceWorkerProviderType = useMemo(() => {
    return { wb };
  }, [wb]);

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  );
}
