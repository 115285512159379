import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';

const ScannerConfirmationPage: React.FunctionComponent = () => {
  const { user } = useAuth();
  if (!user) {
    return null;
  }

  return (
    <div className="ScannerConfirmationPage bg-success h-100 d-flex flex-column justify-content-center">
      <div className="row">
        <div className="col-12 col-md-4 offset-md-4">
          <div className="ScannerConfirmationPage__Confirmation bg-white border rounded text-center flex-column justify-content-between">
            <p className="my-5">Welcome</p>
            <h4>Kenneth Stephens</h4>
            <p className="my-5">4 tickets</p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-4 offset-md-4">
          <div className="ScannerConfirmationPage__Actions my-5">
            <div className="row">
              <div className="col-6 px-4 px-md-2">
                <Link
                  to="/ticket"
                  className="btn btn-secondary btn-outline text-white w-100 rounded-pill"
                >
                  Edit checkin
                </Link>
              </div>

              <div className="col-6 px-4 px-md-2">
                <Link to="/guests" className="btn btn-light w-100 rounded-pill">
                  Done
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScannerConfirmationPage;
