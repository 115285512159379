import React from 'react';
import TicketPurchase, { notesCount } from 'models/TicketPurchase';

interface Props {
  ticketPurchase: TicketPurchase;
  style?: string;
}

const NotesBadge: React.FunctionComponent<Props> = ({
  ticketPurchase,
  style = 'primary',
}) => {
  const count = notesCount(ticketPurchase);
  if (count === 0) return null;

  return <span className={`badge badge-pill badge-${style}`}>{count}</span>;
};

export default NotesBadge;
