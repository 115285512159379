import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getTicketPurchase, getTicketPurchaseActivity } from 'api';
import LoadingSpinner from 'components/core/LoadingSpinner';
import NewNote from 'components/NewNote';
import NoteCard from 'components/NoteCard';
import {
  CurrentScheduleItemProps,
  withCurrentScheduleItem,
} from 'components/withCurrentScheduleItem';
import LoggedInLayout from 'layout/LoggedInLayout';
import Note from 'models/Note';
import { ticketPurchasePath } from 'Routing';
import Activity from 'components/Activity';
import { useAuth } from 'providers/AuthProvider';

interface NotesPageParams {
  [key: string]: string;
  purchaseId: string;
}

type NotesPageProps = NotesPageParams & CurrentScheduleItemProps;

const NotesPage = ({ currentEvent, currentScheduleItem }: NotesPageProps) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const { purchaseId } = useParams<NotesPageParams>();

  const { data: purchase, mutate } = useSWR(
    [
      'ticket-purchase',
      currentEvent.locator,
      currentScheduleItem.id,
      purchaseId,
    ],
    () =>
      getTicketPurchase(
        currentEvent.locator,
        currentScheduleItem.id,
        purchaseId
      )
  );

  useEffect(() => {
    const notes = Array.from(purchase?.notes || []).reverse();
    setNotes(notes);
  }, [purchase]);

  const { data: activity } = useSWR(
    [
      'ticket-purchase-activity',
      currentEvent.locator,
      currentScheduleItem.id,
      purchaseId,
    ],
    () =>
      getTicketPurchaseActivity(
        currentEvent.locator,
        currentScheduleItem.id,
        purchaseId
      )
  );

  const { user } = useAuth();
  if (!user) {
    return null;
  }

  if (!currentEvent || !currentScheduleItem || !purchase) {
    return <LoadingSpinner />;
  }

  const purchasePath = () => {
    return ticketPurchasePath(
      currentEvent.locator,
      currentScheduleItem.id,
      purchase.id
    );
  };

  const doneLink = (
    <Link className="btn btn-primary" to={purchasePath()}>
      Done
    </Link>
  );
  const hasNotes = notes.length > 0;

  return (
    <LoggedInLayout
      currentEvent={currentEvent}
      currentScheduleItem={currentScheduleItem}
      headerLeftContent={doneLink}
    >
      <div className="NotesPage">
        <div className="NotesPage__Header">
          <h1 className="h5">
            Notes:{' '}
            <span className="text-muted">
              {purchase.firstName} {purchase.lastName}
            </span>
          </h1>

          <hr className="my-3" />
        </div>

        <div className="NotesPageSection d-flex flex-column">
          <NewNote ticketPurchase={purchase} onNoteAdded={mutate} />
        </div>

        {hasNotes && (
          <div className="NotesPageSection d-flex flex-column">
            {notes.map((n, idx) => {
              const key = n.id || `note-${idx}`;
              return <NoteCard note={n} key={key} />;
            })}
          </div>
        )}

        <Activities activity={activity as any[]} />
      </div>
    </LoggedInLayout>
  );
};

export default withCurrentScheduleItem(NotesPage);

interface ActivitiesProps {
  activity?: any[];
}
export function Activities({ activity }: ActivitiesProps) {
  if (!(activity && Array.isArray(activity))) return null;
  return (
    <div className="NotesPageSection">
      <h1 className="h5">Activity</h1>
      {activity.map((a, idx) => {
        const key = `activity-${idx}`;
        return <Activity activity={a} key={key} />;
      })}
    </div>
  );
}
