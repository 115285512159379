import logoPath from 'images/logo-light.svg';
import React, { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

const EventListLayout: React.FunctionComponent<Props> = ({
  children,
  leftContent,
  rightContent,
}) => {
  useEffect(() => {
    document.body.classList.add('bg-dark');
    document.body.classList.remove('LoggedIn');
  });
  return (
    <div className="EventListLayout">
      <div className="row">
        <div className="col-3">
          <div className="text-left h-100 d-flex flex-column justify-content-center">
            {leftContent}
          </div>
        </div>

        <div className="col-6 text-center py-md-5 py-2">
          <div className="LoggedInLayout__Logo my-5 text-center">
            <img alt="StagePilot" src={logoPath} width="150" />
          </div>
        </div>
        <div className="col-2 d-flex flex-column justify-content-center">
          <div className="float-end d-flex flex-column align-items-center justify-content-center">
            {rightContent}
          </div>
        </div>
      </div>
      <div className="container py-3">{children}</div>
    </div>
  );
};

export default EventListLayout;
