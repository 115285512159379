import React from 'react';
import Event from 'models/Event';
import ScheduleItem from 'models/ScheduleItem';
import TicketPurchase, { fullName } from 'models/TicketPurchase';

interface PurchaseDetailsProps {
  event: Event;
  scheduleItem: ScheduleItem;
  ticketPurchase: TicketPurchase;
}

const PurchaseDetails: React.FunctionComponent<PurchaseDetailsProps> = ({
  event,
  scheduleItem,
  ticketPurchase,
}) => {
  return (
    <div className="Details">
      <div className="row">
        <PurchaseDetail
          name="Order number"
          value={ticketPurchase.originalOrderNumber}
        />
        <PurchaseDetail
          name="Package"
          value={ticketPurchase.ticketPackage.name}
        />
        <PurchaseDetail
          name="Imported Package"
          value={ticketPurchase.package}
        />
        <PurchaseDetail name="Section" value={ticketPurchase.section} />
        <PurchaseDetail
          name="Seat Location"
          value={ticketPurchase.seatLocation}
        />

        <PurchaseDetail name="Row" value={ticketPurchase.row} />
        <PurchaseDetail name="Receipt ID" value={ticketPurchase.receiptID} />
        <PurchaseDetail name="Order name" value={fullName(ticketPurchase)} />
        <PurchaseDetail name="Phone" value={ticketPurchase.phone} />
        <PurchaseDetail name="Event day" value={scheduleItem.startsAt} />
        <PurchaseDetail name="Event time" value={scheduleItem.time} />
        <PurchaseDetail name="Event name" value={event.name} />

        {Object.entries(ticketPurchase.customFields).map(([key, value], i) => (
          <PurchaseDetail key={`extra-field-${i}`} name={key} value={value} />
        ))}
      </div>
    </div>
  );
};

interface PurchaseDetailProps {
  name: string;
  value: string;
}

const PurchaseDetail: React.FunctionComponent<PurchaseDetailProps> = ({
  name,
  value,
}) => {
  return (
    <div className="col-md-6">
      <p>
        <span className="PurchaseDetail__Name fw-bold">{name}:</span>{' '}
        <span className="PurchaseDetail__Name text-dark">{value}</span>
      </p>
    </div>
  );
};

export default PurchaseDetails;
